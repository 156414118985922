module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":200,"backgroundColor":"transparent","wrapperStyle":"margin-left: 0!important; margin-right: 0!important;","linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6b449084105447081b26e4ce2f7cf210"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-FJN9LSFRGL"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-i18n-l10n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLocale":"ca-ES","siteUrl":"http://localhost:8000","locales":[{"locale":"ca-ES","prefix":"ca","slugs":{"/products":"/productes","/about":"/qui-soc","/instruments":"/instruments","/contact":"/contacte","/home":"/inici","/order":"/demanar","/pickups":"/pastilles","/t-shirts":"/samarretes","/gallery":"/galeria","/theory":"/teoria","/services":"/serveis","/strings":"/cordes"},"messages":{"language":"Benvinguts"}},{"locale":"en-GB","prefix":"en","slugs":{},"messages":{"language":"Welcome"}}],"pathBlacklist":["/pages"]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
