exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-fretting-map-tsx": () => import("./../../../src/pages/fretting-map.tsx" /* webpackChunkName: "component---src-pages-fretting-map-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-instruments-tsx": () => import("./../../../src/pages/instruments.tsx" /* webpackChunkName: "component---src-pages-instruments-tsx" */),
  "component---src-pages-order-tsx": () => import("./../../../src/pages/order.tsx" /* webpackChunkName: "component---src-pages-order-tsx" */),
  "component---src-pages-peterson-tsx": () => import("./../../../src/pages/peterson.tsx" /* webpackChunkName: "component---src-pages-peterson-tsx" */),
  "component---src-pages-pickups-tsx": () => import("./../../../src/pages/pickups.tsx" /* webpackChunkName: "component---src-pages-pickups-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-strings-tsx": () => import("./../../../src/pages/strings.tsx" /* webpackChunkName: "component---src-pages-strings-tsx" */),
  "component---src-pages-t-shirts-tsx": () => import("./../../../src/pages/t-shirts.tsx" /* webpackChunkName: "component---src-pages-t-shirts-tsx" */),
  "component---src-pages-theory-tsx": () => import("./../../../src/pages/theory.tsx" /* webpackChunkName: "component---src-pages-theory-tsx" */),
  "component---src-pages-xelo-tsx": () => import("./../../../src/pages/xelo.tsx" /* webpackChunkName: "component---src-pages-xelo-tsx" */),
  "component---src-pages-ximo-tsx": () => import("./../../../src/pages/ximo.tsx" /* webpackChunkName: "component---src-pages-ximo-tsx" */)
}

